import { Box, Button, TextField } from "@mui/material";

import { ActionFunctionArgs, redirect } from "react-router";
import { Link } from "react-router-dom";
import { useActionData, useLoaderData } from "react-router-typesafe";

import { getSessionState, submitPersonalia } from "~/api";
import { FormContainer } from "~/components/FormContainer";
import { localize } from "~/localize";

export async function loader() {
	let state = await getSessionState();

	if (!state.hasIdentity) {
		return redirect("/");
	} else if (!state.isAuthenticated && state.tokenSent) {
		return redirect("/verify-token");
	}

	return { state };
}

export async function action({ request }: ActionFunctionArgs) {
	let formData = await request.formData();
	let firstName = formData.get("firstName");
	let lastName = formData.get("lastName");
	let company = formData.get("company");

	if (!firstName || !lastName || !company) {
		return localize("Alle feltene må fylles ut.", "All fields must be filled out.");
	}

	let state = await submitPersonalia(firstName as string, lastName as string, company as string);

	if (state.error) {
		return state.error;
	}

	return redirect("/courses");
}

export function Component() {
	let { state } = useLoaderData<typeof loader>();
	let error = useActionData<typeof action>();

	return (
		<FormContainer language={state.language} error={error}>
			<TextField autoFocus label={localize("Fornavn", "First name")} name="firstName" defaultValue={state.firstName} />
			<TextField label={localize("Etternavn", "Last name")} name="lastName" defaultValue={state.lastName} />
			<TextField label={localize("Firma", "Company")} name="company" defaultValue={state.company} />
			<Box display="flex" justifyContent="space-between" gap={2}>
				<Button component={Link} to="/logout" size="large" variant="outlined" type="submit">
					{localize("Tilbake", "Back")}
				</Button>
				<Button size="large" variant="contained" type="submit">
					{localize("Lagre", "Save")}
				</Button>
			</Box>
		</FormContainer>
	);
}
